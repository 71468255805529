export function isCurrentDateBetween(startDate, endDate) {
  if (!startDate || !endDate) return false
  // Parse the input dates to ensure they are Date objects
  let start = new Date(startDate)
  start.setHours(0, 0, 0, 0)
  let end = new Date(endDate)
  end.setHours(0, 0, 0, 0)

  // Get the current date without time
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)

  // Check if the current date is between the start and end dates (inclusive)
  return currentDate >= start && currentDate <= end
}

export function formatDate(date) {
  const d = new Date(date)
  const day = String(d.getDate()).padStart(2, '0')
  const month = String(d.getMonth() + 1).padStart(2, '0')
  const year = d.getFullYear()
  return `${day}.${month}.${year}`
}
