import { useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import useDelayedHover from '@sholdi/hooks/useDelayedHover'
import Link from '@sholdi/primitives/atoms/Link'
import ClickOutside from '../../lib/ClickOutside'
import Container from '../../molecules/Container'
import MenuItem from './MenuItem'

const MegaMenuBanner = dynamic(() => import('./MegaMenuBanner'))

/**
 * Checks if a menu item has any subitems
 * @param {Object} item - Menu item to check
 * @returns {boolean} - True if item has subitems, false otherwise
 */
const hasSubItems = item => {
  const subItems = item?.shopMenuItems || item?.menuItems || []
  return subItems.length > 0
}

/**
 * MegaMenu component that displays a navigation menu with optional dropdown submenus
 * @component
 */
const MegaMenu = ({ menu }) => {
  const [active, setActive] = useState(false)

  const onHover = e => {
    // Close the menu if hovering over item without subitems
    if (!hasSubItems(e)) {
      setActive(false)
      return
    }
    setActive(e)
  }

  const items = menu?.shopMenuItems || menu?.menuItems || []
  const itemGroup = active?.shopMenuItems || active?.menuItems || []

  const { handleMouseEnter, handleMouseLeave } = useDelayedHover(
    onHover,
    active ? 20 : 300,
  )

  const activeBanner = useMemo(
    () =>
      (menu.settings?.banners ?? []).find(banner => banner.shopMenuItemId === active?.id),
    [menu.settings?.banners, active],
  )

  return (
    <>
      <div className="bg-secondary-main py-4 hidden lg:block">
        <Container className="p-0">
          <div className="flex flex-wrap">
            {items
              .sort((a, b) => a.position - b.position)
              .map((item, index) => (
                <MenuItem
                  onMouseLeave={() => handleMouseLeave({ ...item, p: index + 1 })}
                  onMouseEnter={() => handleMouseEnter({ ...item, p: index + 1 })}
                  item={item}
                  hasSubItems={hasSubItems(item)}
                  key={item.id}
                />
              ))}
          </div>
        </Container>
      </div>
      <div className="relative w-full flex justify-center">
        {!!active && hasSubItems(active) && (
          <ClickOutside active={!!active} onClick={() => setActive(false)}>
            <Container className="bg-white shadow-md mx-auto absolute">
              <div className="flex justify-between">
                <div className="w-full box-border p-7 columns-3">
                  <Link
                    href={active.link}
                    style={{ columnSpan: 'all' }}
                    className="text-primary-main font-medium block mb-6"
                  >
                    {active.title}
                  </Link>
                  {itemGroup.map((item, i) => (
                    <div
                      key={`${item.id}-${i}`}
                      style={{ pageBreakInside: 'avoid' }}
                      className="mb-9"
                    >
                      <Link
                        href={item.link}
                        className="block font-medium text-black mb-4"
                      >
                        {item.title}
                      </Link>
                      {(item?.shopMenuItems ?? item.menuItems).map((childItem, i) => (
                        <Link
                          key={`${childItem.id}-${i}`}
                          href={childItem.link}
                          className="block text-black mb-2"
                        >
                          {childItem.title}
                        </Link>
                      ))}
                    </div>
                  ))}
                </div>
                {active && activeBanner && <MegaMenuBanner banner={activeBanner} />}
              </div>
            </Container>
          </ClickOutside>
        )}
      </div>
    </>
  )
}

const menuItemShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkTypeKey: PropTypes.string,
  position: PropTypes.number,
  icon: PropTypes.string,
  parentId: PropTypes.string,
})

menuItemShape.shopMenuItems = PropTypes.arrayOf(menuItemShape)
menuItemShape.menuItems = PropTypes.arrayOf(menuItemShape)

const bannerShape = PropTypes.shape({
  shopMenuItemId: PropTypes.string.isRequired,
})

MegaMenu.propTypes = {
  menu: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    locationKey: PropTypes.string,
    settings: PropTypes.shape({
      banners: PropTypes.arrayOf(bannerShape),
    }),
    shopMenuItems: PropTypes.arrayOf(menuItemShape),
    menuItems: PropTypes.arrayOf(menuItemShape),
  }).isRequired,
}

MegaMenu.displayName = 'MegaMenu'

export default MegaMenu
