import { memo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

import Box from '../Box'

const IconButton = forwardRef(({ className, ...props }, ref) => (
  <Box
    ref={ref}
    as="button"
    className={twMerge(
      'w-8 h-8 flex items-center justify-center flex-none p-1 bg-transparent border-0 cursor-pointer appearance-none [&>svg]:w-fit [&>svg]:h-fit',
      className,
    )}
    {...props}
  />
))

IconButton.propTypes = {
  className: PropTypes.string,
}

IconButton.displayName = 'IconButton'

export default memo(IconButton)
