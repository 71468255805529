import { memo, useState } from 'react'
import PropTypes from 'prop-types'

import IconButton from '@sholdi/primitives/atoms/IconButton'
import Close from '@sholdi/icons/Close'
import Image from '@sholdi/primitives/atoms/Image'

import useSettings from '@sholdi/hooks/useSettings'

import MenuList from './MenuList'
import UserCtas from './UserCtas'

const MobileMenu = ({ onClose, menuItems = [] }) => {
  const [activeCategory, setActiveCategory] = useState(false)
  const [previous, setPrevious] = useState([])
  const settings = useSettings()
  const { logo, shopName } = settings

  const onChoseParent = item => {
    const previousArr = [...previous, item]
    setPrevious(previousArr)
    setActiveCategory(item)
  }

  const onPrevious = () => {
    const previousArr = [...previous]
    previousArr.pop()
    setPrevious(previousArr)
    setActiveCategory(previousArr.pop())
  }
  return (
    <div className="block bg-primary-main lg:hidden absolute w-full h-screen left-0 top-0 z-[99] bg-white">
      <div className=" bg-primary-main flex justify-between items-center py-4 px-3 border-b border-gray-200">
        <Image src={logo} alt={shopName} width={60} height={60} ar="custom" priority />
        <IconButton onClick={() => onClose()}>
          <Close className="!size-8 strokeWhite" />
        </IconButton>
      </div>
      <MenuList
        onClose={onClose}
        menuItems={!activeCategory ? menuItems : activeCategory?.menuItems}
        category={activeCategory}
        onChoseParent={onChoseParent}
        onPrevious={onPrevious}
      />
      <UserCtas />
    </div>
  )
}

MobileMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
}

export default memo(MobileMenu)
