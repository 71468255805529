import { memo } from 'react'
import Link from '@sholdi/primitives/atoms/Link'
import PropTypes from 'prop-types'

const MenuItem = ({ item, onMouseLeave, onMouseEnter }) => (
  <Link
    href={item.link}
    onMouseLeave={() => onMouseLeave(item)}
    onMouseEnter={() => onMouseEnter(item)}
    className="min-w-max font-body font-medium text-base text-secondary-foreground mx-3 flex-shrink-0"
  >
    {item.title}
  </Link>
)

MenuItem.displayName = 'MenuItem'
MenuItem.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
}

export default memo(MenuItem)
